@import '~@internetstiftelsen/styleguide/src/globals';
@import '~@internetstiftelsen/styleguide/src/configurations/extends';
@import '~@internetstiftelsen/styleguide/src/configurations/typography/typography';
@import '~@internetstiftelsen/styleguide/src/organisms/header/header';
@import '~@internetstiftelsen/styleguide/src/organisms/sections/sections';
@import '~@internetstiftelsen/styleguide/src/structures/article';
@import '~@internetstiftelsen/styleguide/src/molecules/table/table';
@import '~@internetstiftelsen/styleguide/src/atoms/spinner/spinner';
@import '~@internetstiftelsen/styleguide/src/molecules/card/card';
@import '~@internetstiftelsen/styleguide/src/atoms/input/input';
@import '~@internetstiftelsen/styleguide/src/atoms/tooltip/tooltip';
@import '~@internetstiftelsen/styleguide/src/molecules/info-box/info-box';
@import '~@internetstiftelsen/styleguide/src/configurations/grid/grid';
@import '~@internetstiftelsen/styleguide/src/configurations/colors/colors';
@import '~@internetstiftelsen/styleguide/src/configurations/colors/_background-colors';
@import '~@internetstiftelsen/styleguide/src/organisms/search/search';
@import '~@internetstiftelsen/styleguide/src/atoms/button/button';
@import '~@internetstiftelsen/styleguide/src/organisms/footer/footer';

/* Remove green outline on Chrome when focusing elements */
*:focus {
  outline: none;
}

.onFocus {
  & *:focus {
    box-shadow: 0 0 0 2px inset #ff4069 !important;
    outline: 0;
  }
}

.width-full {
  max-width: none !important;
}

.help-text {
  position: relative;
  background-color: $color-cyberspace;
  color: $color-snow;
  font-family: $font-family-base;
  font-size: $size-small;
  text-align: left;
  border-radius: $border-radius;
  padding: 0.5rem;

  width: rem(300px);

  &::after {
    content: ' ';
    position: absolute;
    right: rem(-29px);
    width: 0;
    height: 0;
    border: solid transparent;
    border-width: 15px;
    pointer-events: none;
    bottom: calc(50% - 15px);
    border-left-color: $color-cyberspace;
  }
}

.toolbar-actions {
  display: flex;
  justify-content: flex-end;
  align-items: stretch;

  .action-btn {
    padding: rhythm(1.112) rhythm(2);
    border-radius: 0;

    &.active {
      background-color: $color-ash;
    }

    .a-button__text {
      padding-right: 7px;
    }
  }
}

.json-box {
  pre {
    padding-bottom: 0;
    margin-bottom: 0;
  }
}
